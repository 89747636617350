import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { SiGooglegemini } from "react-icons/si";
import { gsap } from "gsap";
import "./Search.js";
import Search from "./Search.js";

Modal.setAppElement("#root");

function AI() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, scale: 0.8, x: 100, y: -50 },
        { opacity: 1, scale: 1, x: 0, y: 0, duration: 0.5 }
      );
    }
  }, [isModalOpen]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: "flex", marginTop: -10 }}>
        <SiGooglegemini
          size={25}
          onClick={handleOpenModal}
          style={{ cursor: "pointer", color: "#007bff" }}
        />
        <sup style={{ fontSize: 7 }}>Beta</sup>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Search Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            maxWidth: "90%",
            maxHeight: "80%",
            width: "100%",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            color: "#00ffbf",
            borderRadius: "12px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            overflowY: "auto",
            backgroundColor: "#2a2f4c",
            borderStyle: "none",
          },
        }}
        ref={modalRef}
      >
        <Search />
      </Modal>
    </div>
  );
}

export default AI;
