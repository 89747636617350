import React, { useEffect } from "react";
import { gsap } from "gsap";
import { about } from "../../portfolio";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./About.css";

const About = () => {
  const { name, role, description, resume, social } = about;
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  useEffect(() => {
    gsap.from(".about__name", {
      opacity: 0,
      duration: 1,
      delay: 2,
      y: 2,
      ease: "elastic.out(1, 0.3)",
    });

    gsap.from(".link--icon", {
      opacity: 0,
      duration: 1,
      delay: 1,
      stagger: 0.2,
      y: -30,
      ease: "elastic.out(1, 0.3)",
      repeat: -1,
      yoyo: true,
    });

    gsap.from(".about__desc", {
      opacity: 0,
      duration: 1,
      y: 30,
      delay: 1,
    });
  }, []);

  return (
    <div className="about center">
      <div>
        {name && (
          <h1>
            Hi, I am <span className="about__name">{name}.</span>
          </h1>
        )}

        {role && <h2 className="about__role">A {role}.</h2>}
        <p className="about__desc">{description && description}</p>
      </div>

      <div className="about__contact center">
        {/* {resume && (
          <a href={resume}> */}
        <span
          type="button"
          className="btn btn--outline"
          onClick={() =>
            openInNewTab(
              "https://docs.google.com/document/d/1HziQl7ST5KGXcXN70myrvTK15Rzlw2QZXO4HUq8Z-l8"
            )
          }
        >
          Resume
        </span>
        {/* </a>
        )} */}

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label="github"
                className="link link--icon"
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label="linkedin"
                className="link link--icon"
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default About;
