import React, { useEffect, useState } from "react";
import uniqid from "uniqid";
import { projects } from "../../portfolio";
import ProjectContainer from "../ProjectContainer/ProjectContainer";
import "./Projects.css";
import Spline from "@splinetool/react-spline";

const Projects = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!projects.length) return null;

  return (
    <section id="projects" className="section projects">
      {isDesktop && (
        <Spline scene="https://prod.spline.design/BZHOH72f7lJZ4TUa/scene.splinecode" />
      )}
      
      <h2 className="section__title">Projects</h2>

      <div className="projects__grid">
        {projects.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>
    </section>
  );
};

export default Projects;
