import React, { useEffect, useState } from "react";
import "./Cursor.css";

const Cursor = () => {
  const [cursorStyle, setCursorStyle] = useState({ top: 0, left: 0 });
  const [expandCursor, setExpandCursor] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorStyle({ top: e.pageY - 10, left: e.pageX - 10 });
    };

    const handleMouseClick = () => {
      setExpandCursor(true);

      setTimeout(() => {
        setExpandCursor(false);
      }, 500);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("click", handleMouseClick);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("click", handleMouseClick);
    };
  }, []);

  return (
    <div>
      <div
        className={`cursor${expandCursor ? " expand" : ""}`}
        style={{ top: `${cursorStyle.top}px`, left: `${cursorStyle.left}px` }}
      ></div>
    </div>
  );
};

export default Cursor;
