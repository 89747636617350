import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";

const Search = () => {
  const [search, setSearch] = useState("");
  const [aiResponse, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  async function aiRun() {
    if (!search) return;

    setLoading(true);
    setError(null);

    try {
      const apiKey = process.env.REACT_APP_GEMINI_API_KEY;
      const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${apiKey}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contents: [
            {
              parts: [
                {
                  text: `Provide a plain text, point-wise description of ${search}, without any Markdown formatting.`,
                },
              ],
            },
          ],
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message || "Failed to fetch AI response"
        );
      }

      const data = await response.json();

      let generatedText = "";
      if (data.candidates && data.candidates.length > 0) {
        const firstCandidate = data.candidates[0];

        function extractText(obj) {
          if (typeof obj === "string") {
            return obj;
          } else if (Array.isArray(obj)) {
            return obj.map(extractText).join("");
          } else if (typeof obj === "object" && obj !== null) {
            return Object.values(obj).map(extractText).join("");
          }
          return "";
        }

        generatedText = extractText(firstCandidate);

        generatedText = generatedText
          .split("\n")
          .map((line) => (line.trim() ? `<li>${line.trim()}</li>` : ""))
          .filter((line) => line)
          .join("\n");

        generatedText = `<ul style="display: grid; grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); gap: 20px;">${generatedText}</ul>`;
      }

      setResponse(generatedText);
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setError(error.message);
      setResponse("");
    } finally {
      setLoading(false);
    }
  }

  const handleClick = () => {
    aiRun();
  };

  return (
    <div style={{ fontFamily: "'Poppins', sans-serif" }}>
      <h3
        style={{
          marginBottom: 10,
          color: "#cdcdff",
        }}
      >
        Generative AI
      </h3>

      <div style={{ display: "flex" }}>
        <input
          onChange={(e) => handleChangeSearch(e)}
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "15px",
            border: "1px solid #ddd",
            borderRadius: 50,
          }}
          placeholder="Search here"
        />
        <button
          style={{
            marginLeft: 20,
            backgroundColor: "#007bff",
            border: "none",
            borderRadius: 100,
            color: "#fff",
            cursor: "pointer",
            transition: "background-color 0.3s",
            height: 40,
            width: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleClick}
        >
          <IoSearch />
        </button>
      </div>

      {loading ? (
        <p style={{ margin: "30px 0" }}>Loading ...</p>
      ) : error ? (
        <p style={{ margin: "30px 0", color: "red" }}>{error}</p>
      ) : (
        <div style={{ margin: "30px 0" }}>
          <div
            style={{ overflow: "hidden" }}
            dangerouslySetInnerHTML={{ __html: aiResponse }}
          />
        </div>
      )}
    </div>
  );
};

export default Search;
